const title = function(title) {
	document.title = title;
}

// 手机号验证
const is_phone = function(phone) {
	let br = true;
	if (!(/^0?(13[0-9]|14[5-9]|15[012356789]|166|17[0-9]|18[0-9]|19[8-9])[0-9]{8}$/.test(phone))) {
		br = false
	}
	return br
}
// 邮箱验证
const is_email = function(email) {
	let br = true;
	if (!(/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(email))) {
		br = false
	}
	return br
}
// 非空验证
const is_text = function(text) {
	let br = true;
	if (!(/\S/.test(text))) {
		br = false
	}
	return br
}
// 密码验证
const is_password = function(text) {
	let br = true;
	if (!(/^[a-zA-Z0-9]{6,12}$/.test(text))) {
		br = false
	}
	if (!(/^(?![^a-zA-Z]+$)(?!\D+$)/.test(text))) {
		br = false
	}
	return br
}

const isMobile = function() {
	return navigator.userAgent.match(
		/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
	)
}

// 身份证验证
const is_card = function(code) {
	//身份证号合法性验证
	//支持15位和18位身份证号
	//支持地址编码、出生日期、校验位验证
	var city = {
		11: "北京",
		12: "天津",
		13: "河北",
		14: "山西",
		15: "内蒙古",
		21: "辽宁",
		22: "吉林",
		23: "黑龙江 ",
		31: "上海",
		32: "江苏",
		33: "浙江",
		34: "安徽",
		35: "福建",
		36: "江西",
		37: "山东",
		41: "河南",
		42: "湖北 ",
		43: "湖南",
		44: "广东",
		45: "广西",
		46: "海南",
		50: "重庆",
		51: "四川",
		52: "贵州",
		53: "云南",
		54: "西藏 ",
		61: "陕西",
		62: "甘肃",
		63: "青海",
		64: "宁夏",
		65: "新疆",
		71: "台湾",
		81: "香港",
		82: "澳门",
		91: "国外 "
	};
	var row = true;
	var msg = "验证成功";

	if (!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|[xX])$/.test(code)) {
		row = false,
			msg = "被保人身份证号格式错误";
	} else if (!city[code.substr(0, 2)]) {
		row = false,
			msg = "被保人身份证号地址编码错误";
	} else {
		//18位身份证需要验证最后一位校验位
		if (code.length == 18) {
			code = code.split('');
			//∑(ai×Wi)(mod 11)
			//加权因子
			var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
			//校验位
			var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
			var sum = 0;
			var ai = 0;
			var wi = 0;
			for (var i = 0; i < 17; i++) {
				ai = code[i];
				wi = factor[i];
				sum += ai * wi;
			}
			if (parity[sum % 11] != code[17].toUpperCase()) {
				row = false,
					msg = "被保人身份证号校验位错误";
			}
		}
	}
	return row;
}

// 存储信息
const set_data = function(key, val) {
	sessionStorage.setItem(key, JSON.stringify(val));
}
// 读取信息
const get_data = function(key) {
	return JSON.parse(sessionStorage.getItem(key));
}

// 超出省略
const slh = function(str, length) {
	if (str.length > length + 3) {
		str = str.substring(0, length) + "..."
	}
	return str;
}

// 获取当前日期
function getNowDate() {
	let date = new Date();
	let year = date.getFullYear()
	let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
	let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
	return year + '-' + month + '-' + day
}

// 获取当前全部日期
function getNowTime() {
	let dateTime
	let yy = new Date().getFullYear()
	let mm = new Date().getMonth() + 1
	let dd = new Date().getDate()
	let hh = new Date().getHours()
	let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
	let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
	dateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
	return dateTime
}

// 获取当前年月
function getNowYearMonth() {
	let date = new Date();
	let year = date.getFullYear()
	let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
	return year + '-' + month
}

//获取某一天的日期
function getDate(num, str) {
	var today = new Date();
	var nowTime = today.getTime();
	var ms = 24 * 3600 * 1000 * num;
	today.setTime(parseInt(nowTime + ms));
	var oYear = today.getFullYear();
	var oMoth = (today.getMonth() + 1).toString();
	if (oMoth.length <= 1) oMoth = '0' + oMoth;
	var oDay = today.getDate().toString();
	if (oDay.length <= 1) oDay = '0' + oDay;
	return oYear + str + oMoth + str + oDay;
}

// 获取往前推m个月的日期
function getPreMonthDay(m) {
	let dt = new Date();
	dt.setMonth(dt.getMonth() - m);
	return formatDate(dt);
}

//使用递归的方式实现数组、对象的深拷贝
function deepClone(obj) {
	//判断拷贝的要进行深拷贝的是数组还是对象，是数组的话进行数组拷贝，对象的话进行对象拷贝
	let objClone = Array.isArray(obj) ? [] : {};
	//进行深拷贝的不能为空，并且是对象或者是
	if (obj && typeof obj === "object") {
		for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
				if (obj[key] && typeof obj[key] === "object") {
					objClone[key] = deepClone(obj[key]);
				} else {
					objClone[key] = obj[key];
				}
			}
		}
	}
	return objClone;
}
//数组是否包含id的对象
function arrayContainId(array, id) {
	for (var i = 0; i < array.length; i++) {
		let item = array[i]
		if (item.id === id) {
			return true
		}
	}
	return false
}
//秒数转时分秒
//type(0): 01:02:03
//type(1): 01:02′03″
//type(2): 01时02分03秒
function formatSeconds(value, type) {
	var secondTime = parseInt(value); // 秒
	var minuteTime = 0; // 分
	var hourTime = 0; // 小时
	if (secondTime >= 60) { //如果秒数大于60，将秒数转换成整数
		//获取分钟，除以60取整数，得到整数分钟
		minuteTime = parseInt(secondTime / 60);
		//获取秒数，秒数取佘，得到整数秒数
		secondTime = parseInt(secondTime % 60);
		//如果分钟大于60，将分钟转换成小时
		if (minuteTime >= 60) {
			//获取小时，获取分钟除以60，得到整数小时
			hourTime = parseInt(minuteTime / 60);
			//获取小时后取佘的分，获取分钟除以60取佘的分
			minuteTime = parseInt(minuteTime % 60);
		}
	}
	var secondStr = '';
	var minuteStr = '';
	var hourStr = '';
	if (secondTime < 10) {
		secondStr = '0' + secondTime
	} else {
		secondStr = secondTime
	}
	if (minuteTime < 10) {
		minuteStr = '0' + minuteTime
	} else {
		minuteStr = minuteTime
	}
	if (hourTime < 10) {
		hourStr = '0' + hourTime
	} else {
		hourStr = hourTime
	}
	//type(0): 01:02:03
	//type(1): 01:02′03″
	//type(2): 01时02分03秒
	var result = "";
	if (type === 0) {
		if (hourTime > 0) {
			result += hourStr + ':' + minuteStr + ':' + secondStr
		} else {
			result += minuteStr + ':' + secondStr
		}
	} else if (type === 1) {
		if (hourTime > 0) {
			result += hourStr + ':' + minuteStr + '′' + secondStr + '″'
		} else {
			result += minuteStr + '′' + secondStr + '″'
		}
	} else if (type === 2) {
		if (hourTime > 0) {
			result += hourStr + '时' + minuteStr + '分' + secondStr + '秒'
		} else {
			result += minuteStr + '分' + secondStr + '秒'
		}
	}
	return result;
}
/**
 * 去掉小数末尾的0
 */
function cutZero(old) {
	old = '' + old
	//拷贝一份 返回去掉零的新串
	let newstr = old;
	//循环变量 小数部分长度
	let leng = old.length - old.indexOf(".") - 1
	//判断是否有效数
	if (old.indexOf(".") > -1) {
		//循环小数部分
		for (let i = leng; i > 0; i--) {
			//如果newstr末尾有0
			if (newstr.lastIndexOf("0") > -1 && newstr.substr(newstr.length - 1, 1) == 0) {
				let k = newstr.lastIndexOf("0");
				//如果小数点后只有一个0 去掉小数点
				if (newstr.charAt(k - 1) == ".") {
					return newstr.substring(0, k - 1);
				} else {
					//否则 去掉一个0
					newstr = newstr.substring(0, k);
				}
			} else {
				//如果末尾没有0
				return newstr;
			}
		}
	}
	return old;
}
/**
 * 四舍五入
 * @param num 小数
 * @param s 保留位数
 * @returns {string}
 */
function toFixed(num, s) {
	if (Object.is(parseFloat(num), NaN)) {
		return console.log(`传入的值：${num}不是一个数字`);
	}
	//解决四舍六入问题，5强制加1进行四舍五入
	num += '';
	let numArray = num.split('.');
	if (numArray.length > 1) { //有小数部分
		let part2 = numArray[1];
		if (part2.length > s) {
			let last = part2.charAt(s)
			if (Number(last) === 5) {
				num = Number(num) + Math.pow(0.1, s + 1)
			}
		}
	}
	//-----------------------------
	num = parseFloat(num);
	let res = (Math.round((num + Number.EPSILON) * Math.pow(10, s)) / Math.pow(10, s)).toFixed(s);
	return cutZero(res)
}
/**
 * @param {Object} 要转化的对象
 */
function getParamsString(obj) {
	let paramStr = ''
	for (let key of Object.keys(obj)) {
		let name = obj[key];
		if (paramStr === '') {
			paramStr += key + '=' + name
		} else {
			paramStr += '&' + key + '=' + name
		}
	}
	return paramStr
}

// 将el-icon的组件名称AbbbCddd转化为i-abbb-cddd形式
// 此前用switch做组件名时因关键字重复报错，所以格式统一加了前缀
// 例：Switch转换为i-switch，ArrowDownBold转换为i-arrow-down-bold
export function transElIconName(iconName) {
	return iconName.replace(/[A-Z]/g, (match) => match.toLowerCase())
}
/**
 * 检查输入框
 * @param {Object} value  值
 * @param {Object} number 小数位数
 */
function checkInput(value, number) {
	if (value === null) {
		value = ''
	} else {
		value += ''
	}
	if (number === 0) {
		if (value.indexOf('.') !== -1) {
			value = value.substr(0, value.indexOf('.'))
		}
		if (value.indexOf('。') !== -1) {
			value = value.substr(0, value.indexOf('。'))
		}
		return (value.match(/^\d*(\.?\d{0,0})/g)[0]) || ''
	} else if (number === 1) {
		return (value.match(/^\d*(\.?\d{0,1})/g)[0]) || ''
	} else if (number === 2) {
		return (value.match(/^\d*(\.?\d{0,2})/g)[0]) || ''
	} else if (number === 3) {
		return (value.match(/^\d*(\.?\d{0,3})/g)[0]) || ''
	} else if (number === 4) {
		return (value.match(/^\d*(\.?\d{0,4})/g)[0]) || ''
	}
}
/**
 * 数组分成多个数组
 * @param array		要分割的数组
 * @param subGroupLength  每个数组的长度
 * @returns {*[]}
 */
function arraySplit(array, subGroupLength) {
	if (!array) {
		return []
	}
	let index = 0;
	let newArray = [];
	while (index < array.length) {
		newArray.push(array.slice(index, index += subGroupLength));
	}
	return newArray;
}

//计算对象数组中某个属性合计
function countTotal(arr, keyName) {
	let $total = 0;
	$total = arr.reduce(function(total, currentValue, currentIndex, arr) {
		return currentValue[keyName] ? (total + currentValue[keyName]) : total;
	}, 0);
	return toFixed($total, 3);
}

//判断是否为空
function isEmpty(value) {
	return value === null || value === undefined || value.toString().trim() === '';
}
//获取当前日期周一
function getFirstDayOfWeek(date) {
	let day = date.getDay() || 7;
	return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1 - day);
}
//获取当前日期周日
function getLastDayOfWeek(date) {
	let day = date.getDay() || 7;
	return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7 - day);
}
//格式化日期
function formatDate(date) {
	let year = date.getFullYear()
	let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
	let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
	return year + '-' + month + '-' + day
}
//格式化时间
function formatTime(date) {
	let yy = date.getFullYear()
	let mm = date.getMonth() + 1
	let dd = date.getDate()
	let hh = date.getHours()
	let mf = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
	let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
	return yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
}

//计算两个日期之间的年数
function getDateInterval(startDate, endDate) {
	let sDate = new Date(Date.parse(startDate.replace(/-/g, "/")));
	let eDate = new Date(Date.parse(endDate.replace(/-/g, "/")));
	//获得各自的年、月、日
	let sY = sDate.getFullYear();
	let sM = sDate.getMonth() + 1;
	let sD = sDate.getDate();
	let eY = eDate.getFullYear();
	let eM = eDate.getMonth() + 1;
	let eD = eDate.getDate();
	let yL = eY - sY;
	let mL = eM - sM;
	let dL = eD - sD;
	if (dL < 0) {
		mL--;
	}
	if (mL < 0) {
		yL--;
	}
	return yL > 0 ? yL : 0;
}

//获取几天前的日期
function getBeforeDay(n) {
	let date = new Date();
	let separator = "-"
	let newDate = new Date(date.getTime() - n * 24 * 60 * 60 * 1000);
	let year = newDate.getFullYear();
	let month = newDate.getMonth() + 1;
	let day = newDate.getDate();
	return year.toString() + separator + (month < 10 ? '0' + month.toString() : month.toString()) + separator + (day <
		10 ? '0' + day.toString() : day.toString())
}

export default {
	title, //获取页面标题
	is_phone, // 验证手机号
	is_email, // 邮箱验证
	is_text, // 非空验证
	is_password, // 密码验证
	is_card, // 身份证验证
	set_data, // 存储信息
	get_data, // 读取信息
	slh, // 超出省略
	isMobile, //是否是手机
	getNowDate, //获取当前日期
	getNowTime, //获取当前时间
	getNowYearMonth, //获取当前年月
	getDate, //获取某一天的日期
	getPreMonthDay, //获取往前推几个月的日期
	deepClone, //深拷贝对象
	arrayContainId, //数组包含某个id
	formatSeconds, //格式化秒数
	cutZero, //去掉小数末尾的0
	toFixed, //四舍五入保留小数
	getParamsString, //转化对象为请求参数
	checkInput, //检查输入框
	arraySplit, //数组分成多个数组
	countTotal, //计算对象数组中某个属性合计
	isEmpty, //是否为空
	getFirstDayOfWeek, //获取当前日期周一
	getLastDayOfWeek, //获取当前日期周日
	formatDate, //格式化日期
	formatTime, //格式化时间
	getDateInterval, //计算两个日期之间的年数
	getBeforeDay, //获取几天前的日期
}