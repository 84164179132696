import axios from 'axios'
import Router from '../router'
import {
  ElMessage,
  ElMessageBox
} from 'element-plus' // 这里用的element-ui中的ElMessage消息提示插件

// 服务器域名
axios.defaults.baseURL = 'https://xinlianshouhuan.com/admin-api' //服务器

// 每一次请求不可能都要手动添加一个参数吧，所以在这里定义一个拦截器，请求发送，在这里自动将请求拦截下来，自动的往请求里面加一个参数
axios.interceptors.request.use(config => {
  let token = sessionStorage.getItem('token')
  if (config.url.indexOf('/login') === -1) {
    config.headers['Authorization'] = token ? token : '';
  }
  return config
}, err => {
  ElMessage.error({
    message: '请求超时'
  })
  return Promise.resolve(err)
})
// 拦截服务端响应的信息
axios.interceptors.response.use(data => {
  if (data.status && data.status === 200 && data.data.code && data.data.code !== 200) {
    ElMessage.error({
      message: data.data.msg
    })
    if (data.data.code === 401) {
      setTimeout(() => {
        Router.replace({
          path: '/login',
          query: {},
        })
      }, 1000)
    } else if (data.data.code === 7000) { //对话框
      ElMessageBox.confirm(data.data.msg, '温馨提示', {
        confirmButtonText: '知道了',
        showCancelButton: false,
        type: 'warning',
      }).then(() => {
      }).catch(() => {
      })
    } else if (data.data.code === 6000) { //异常带数据的
      return data.data
    } else { //吐丝
      ElMessage.error({
        message: data.data.msg
      })
    }
    return
  }
  // 返回方法调用的那里，拿到的就是服务端返回的数据
  if (data.data) {
    return data.data
  }
}, err => {
  // 如果HTTP响应码是400,500等就会进入这个err函数中
  // 如果服务器没启动或者是路径错误进入此判断中
  if (err.response.status === 504 || err.response.status === 404) {
    ElMessage.error({
      message: '服务器连接失败'
    })
  } else if (err.response.status === 403) { // 表示权限不足
    ElMessage.error({
      message: '权限不足，请联系管理员'
    })
  } else if (err.response.status === 401) { // 表示未登录
    ElMessage.error({
      message: err.response.data.msg // 服务器返回来的信息
    })
  } else {
    if (err.response.data.msg) {
      ElMessage.error({
        message: err.response.data.msg
      })
    } else {
      ElMessage.error({
        message: '未知错误'
      })
    }
  }
})
// get请求接口
export const getRequest = (url, params) => {
  let paramStr = ''
  for (let key of Object.keys(params)) {
    let name = params[key];
    if (paramStr === '') {
      paramStr += key + '=' + name
    } else {
      paramStr += '&' + key + '=' + name
    }
  }
  return axios({
    method: 'get',
    url: `${url}` + '?' + paramStr
  })
}
// post请求接口
export const postRequest = (url, params) => {
  return axios({
    method: 'post',
    url: `${url}`,
    data: params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
// post表单参数接口
export const postFormRequest = (url, params) => {
  return axios({
    method: 'post',
    url: `${url}`, // url的拼接，base就是我们定义的前缀，url就是你传进来的请求
    data: params,
    transformRequest: [function (data) {
      let ret = ''
      for (let it in data) {
        if (ret === '') {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it])
        } else {
          ret += '&' + encodeURIComponent(it) + '=' + encodeURIComponent(data[it])
        }
      }
      return ret
    }],
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
// 文件上传接口
export const uploadFileRequest = (url, params) => {
  return axios({
    method: 'post',
    url: `${url}`,
    data: params,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
// 文件直传七牛云接口
export const uploadFileQiNiu = (file, success, error, process) => {
  postRequest('/index/qiniuToken', {}).then((res) => {
    if (res) {
      //上传
      let formData = new FormData();
      formData.append("fileName", file.name ? file.name.split('.')[0] : new Date().getTime());
      formData.append("file", file);
      formData.append("token", res.data.token);
      formData.append("serverUrl", res.data.domain);
      formData.append("bucketname", res.data.bucket);
      formData.append("key", res.data.key);
      axios({
        method: 'post',
        url: 'https://up-z1.qiniup.com',
        data: formData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        onUploadProgress: function (progressEvent) {
          let percent = progressEvent.progress * 100
          if (process) {
            process(percent)
          }
        }
      }).then((res1) => {
        if (res1) {
          if (success) {
            success(res.data.domain + '/' + res1.key)
          }
        }
      }).catch((err) => {
        if (error) {
          error(err)
        }
      })
    }
  }).catch((error) => {
    console.log(error)
  })
}
// 文件直传阿里云接口
export const uploadFileAli = (file, success, error, process) => {
  getRequest('/articles/ossConfig', {}).then((res) => {
    if (res) {
      //上传
      let formData = new FormData();
      let fileName = (new Date().getTime()) + '_' + file.name
      formData.append("key", res.data.dir + fileName);
      formData.append("Signature", res.data.signature);
      formData.append("policy", res.data.policy);
      formData.append("OSSAccessKeyId", res.data.accessid);
      formData.append('success_action_status', '200');
      formData.append("Filename", fileName);
      formData.append("file", file);
      axios({
        method: 'post',
        url: res.data.host,
        data: formData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        onUploadProgress: function (progressEvent) {
          let percent = progressEvent.progress * 100
          if (process) {
            process(percent)
          }
        }
      }).then((res1) => {
        if (success) {
          success(res.data.host + '/' + res.data.dir + fileName)
        }
      }).catch((err) => {
        if (error) {
          error(err)
        }
      })
    }
  }).catch((error) => {
    console.log(error)
  })
}
// put请求接口
export const putRequest = (url, params) => {
  return axios({
    method: 'put',
    url: `${url}`,
    data: params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
export const putFormRequest = (url, params) => {
  return axios({
    method: 'put',
    url: `${url}`,
    data: params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}
// delete请求接口
export const deleteRequest = (url) => {
  return axios({
    method: 'delete',
    url: `${url}`
  })
}
