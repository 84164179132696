import {
	createStore
} from 'vuex'

export default createStore({
	state: { //存放组件之间共享的数据
		//用户是否登录
		login: false,
	},
	mutations: { //显式的更改state里的数据，触发用this.store.commit('test1',10)
		test1: function(state, a) {
			console.log(state.login = true);
		}
	},
	getters: { //获取数据的方法
		getTest: function(state) {
			return state.login;
		}
	},
	actions: { //异步执行的方法，触发用this.store.dispatch('test2', 5);
		//设置延时
		test2: function(context, value) {
			setTimeout(function() {
				//提交事件
				context.commit('test1', value);
			}, 1000)

		}
	}
});
