import {
  createRouter,
  createWebHashHistory
} from 'vue-router'

const routes = [{
  path: '/',
  name: 'index',
  component: () => import('@/views/index.vue'),
  meta: {
    keepAlive: true,
  },
  children: [{
    path: '/home',
    name: '/home',
    component: () => import('@/views/home.vue')
  }, {
    path: '/deviceList',
    name: 'deviceList',
    component: () => import('@/views/deviceManage/deviceList.vue')
  }, {
    path: '/warningMessage',
    name: 'warningMessage',
    component: () => import('@/views/warningMessage/warningMessage.vue')
  }, {
    path: '/warningClass',
    name: 'warningClass',
    component: () => import('@/views/warningClass/warningClass.vue')
  }, {
    path: '/safeManage',
    name: 'safeManage',
    component: () => import('@/views/contentManage/safeManage.vue')
  }, {
    path: '/articleManage',
    name: 'articleManage',
    component: () => import('@/views/contentManage/articleManage.vue')
  }, {
    path: '/userManage',
    name: 'userManage',
    component: () => import('@/views/userManage/userManage.vue')
  }, {
    path: '/systemUser',
    name: 'systemUser',
    component: () => import('@/views/systemManage/systemUser.vue')
  }, {
    path: '/roleManage',
    name: 'roleManage',
    component: () => import('@/views/systemManage/roleManage.vue')
  }, {
    path: '/orgManage',
    name: 'orgManage',
    component: () => import('@/views/systemManage/orgManage.vue')
  }, {
    path: '/paramManage',
    name: 'paramManage',
    component: () => import('@/views/systemManage/paramManage.vue')
  }, {
    path: '/suggestManage',
    name: 'suggestManage',
    component: () => import('@/views/suggestManage/suggestManage.vue')
  }, {
    path: '/health',
    name: 'health',
    component: () => import('@/views/systemManage/health.vue')
  }, {
    path: '/systemParam',
    name: 'systemParam',
    component: () => import('@/views/systemManage/systemParam.vue')
  }]
},
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
